.draggable {
  padding: 5px;
  margin-right: 1px;
  cursor: grab;
  touch-action: none;
}

.dragging {
  cursor: grabbing;
  opacity: 0;
}