body {
    font-family: 'Poppins', sans-serif;
}

.iconbutton {
    padding: 5px;
    svg {
        color: black;
        font-size: 20px;
    }
    &:hover svg {
        color: #0033FF;
    }
    &.Mui-disabled svg {
        color: #D1D9DE;
    }
}

.passwordtooltip {
    transform: none !important;
    top: max(calc(50vh - 69px), 177px) !important;
    width: 220px;
    height: 102px;
    left: max(180px, calc(50vw - 30px)) !important;
}

.amplify-field:focus-within {
    border: 1px solid transparent;
    .amplify-field-group {
        border: 2px solid #0033FF;
    }
    label.amplify-label {
        top: 12px !important;
        left: 12px !important;
        font-size: 10px;
        color: #0033FF !important;
        background: white;
        z-index: 1;
    }
    .amplify-field-group__outer-end button {
        border: none;
    }
    input {
        box-shadow: none !important;
        border: none !important;
    }
}

.amplify-field-group {
    border: 2px solid transparent;
}

.amplify-label {
    transition: top 0.2s, font-size 0.2s;
    position: relative;
    top: 31px;
    line-height: 11px;
    font-size: 11px;
    color: #91A0AD;
    left: 13px;
    padding: 0 4px;
    width: fit-content;
}

.amplify-alert {
    margin-top: 20px;
    font-size: 11px;
    padding: 9px 14px;
}

.sign-in .amplify-alert {
    position: absolute;
    top: max(calc(50vh - 165px), 85px);
    background-color: transparent;
    width: 350px;
    text-align: center;
    padding: 9px 0px;
    .amplify-alert__body{
        font-style: italic;
        font-size: 10px;
        line-height: 20px;
        letter-spacing: 0.3px;
        color: #ff3037;
    }
    .amplify-alert__icon{
        display: none;
    }
    .amplify-alert__dismiss{
        display: none;
    }
}

.small-username-label {
    .amplify-textfield:not(.amplify-passwordfield) {
        .amplify-label {
            transition: none !important;
            top: 13px;
            font-size: 10px;
            color: black;
            background: white;
            z-index: 1;
        }
    }
}

.small-password-label {
    .amplify-passwordfield {
        .amplify-label {
            transition: none !important;
            top: 13px;
            font-size: 10px;
            color: black;
            background: white;
            z-index: 1;
        }
    }
}

.sign-in.has-error.small-username-label {
    .amplify-textfield {
        .amplify-label {
            color: #ff3037;
        }
    }
}
div[data-amplify-authenticator] {
    background: linear-gradient(90deg, #080830 -13.8%, #0033FF 121.34%);
    height: 100vh;
    div[data-amplify-router] {
        width: 464px;
        box-shadow: 2px 4px 5px 0px #00000066;
        border: 0;
        form {
            padding: 45px 57px 18px 57px;
        }
        [data-amplify-form]>.amplify-flex {
            align-items: center;
            min-height: 280px;
            justify-content: space-between;
            fieldset {
                gap: 40px;
                width: 350px;
            }
        }
        .amplify-input {
            transition: none;
            &[name="password"] {
                border-right: 0;
            }
            font-size: 11px;
            &::placeholder {
                color: #91A0AD;
            }
            border-radius: 0;
        }
        .amplify-field-group__outer-end button {
            transition: none;
            border-radius: 0;
            color: #91A0AD;
            border-width: 1px 1px 1px 0;
            &[aria-checked="true"] .amplify-icon::after {
                content: "\e8f5";
            }
            &[aria-checked="false"] .amplify-icon::after {
                content: "\e8f4";
            }
            .amplify-icon {
                svg {
                    display: none;
                }
                &::after {
                    font-family: "Material Icons";
                }
            }
        }
        .amplify-button--primary {
            width: 200px;
            height: 31px;
            padding: 7px 35px 7px 35px;
            border-radius: 50px;
            color: white;
            background: black;
            font-size: 11px;
            font-weight: 600;
            align-items: center;
            &:hover {
                background: #0033FF;
            }
        }
    }
    .login-checkbox {
        span.MuiCheckbox-root {
            padding: 3px;
            color: #91A0AD;
            &.Mui-checked {
                color: #0033FF;
            }
        }
        label {
            font-weight: 400;
        }
    }
    button.amplify-button--link {
        padding: 0;
        color: #91A0AD;
    }
    button.amplify-button--small {
        font-size: 11px;
    }
    div[data-amplify-footer] {
        padding-bottom: 43px;
    }
}
.sign-in .amplify-input {
    border: 1px solid #91A0AD;
}
.sign-in.has-error .amplify-input {
    border: solid 1px #ff3037;
}
.sign-in .amplify-field-group__outer-end button{
    border: solid 1px #91A0AD;
}
.sign-in.has-error .amplify-field-group__outer-end button{
    border: solid 1px #ff3037;
}
.sign-in.has-error .amplify-icon {
    color:#ff3037;
}

.Mui-selected {
    color: #2550FA;
}

.MuiTabs-indicator {
    background-color: #2550FA !important;
    height: 4px !important;
}

.MuiTabs-scroller {
    .MuiButtonBase-root.MuiTab-root {
        width: 160px;
    }
}

.data-table {
    thead th {
        background-color: transparent;
        color: black;
        font-size: 14px;
        font-weight: 500;
        span {
            min-width: 100px
        }
    }
    tbody {
        border-width: 2px 0;
        border-color: black;
        border-style: solid;

        tr {
            border-width: 1px 0;
            border-color: black;
            border-style: solid;
            height: 55px;
        }
        tr:hover {
            background-color: #EEF3F7;
        }

        td {
            border: 0;
            font-size: 17px;
            font-weight: 500;
        }
    }
}
button.button-height-35 {
    height: 35px !important;
    font-size: 18px;
}

ul.sidebar-container {
    height: max(calc(100vh - 70px), 100%);
    border-right: 1px solid #C8D0D6;
    padding: 0;
    & > div {
        border-bottom: 1px solid #C8D0D6;
    }
}
.page-container {
    margin-bottom: 20px;
    .page-title-container {
        margin: 21px 0 7px;
    }
    .page-title {
        color: #000;
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: 'Poppins', sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 0.25px;
    }
    .page-subtitle {
        color: #91A0AD;
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.25px;
    }
    button.form-button {
        border-radius: 50px;
        font-size: 11px;
        padding: 7px 35px;
    }
}

#media-editor-form-container {
    .form-group-container {
        margin-bottom: 36px;
        &.media-url-container {
            margin-bottom: 10px;
        }
        &:last-child {
            margin-bottom: 0;
        }
        input[type='file'] {
            display: none;
        }
        label {
            color: #000000;
            font-size: 16px;
            margin-bottom: 12px;
            & > span button {
                position: relative;
                bottom: 2px;
            }
            input[type="text"] { padding: 5px 10px; }
        }
        p {
            color: #91A0AD;
            font-size: 12px;
            margin-left: 0;
        }
        &.error-field-group {
            input, p {
                color: #FF3037;
            }
            input, fieldset, .chip-container {
                border-color: #FF3037;
            }
        }
        .file-input-controller label {
            border: 1px solid #91A0AD;
            border-radius: 0;
            font-size: 11px;
            justify-content: flex-start;
            text-align: left;
        }
    }
    .chip-container {
        border: 1px solid #91A0AD;
        cursor: text;
        min-height: 2.25em;
        overflow: hidden;
        padding: 5px;
        width: 100%;
        & > div {
            margin-left: 5px;
            & > svg {
                font-size: 14px;
            }
        }
        span {
            cursor: default;
            font-size: 10px;
            padding: 7px 5px 7px 7px;
        }
        input[type="text"] {
            border: none;
            height: 21px;
            font-size: 11px;
            margin-left: 10px;
            padding: 9px 10px 5px 3px;
            width: 100%;
            &:focus {
                outline: none;
            }
        }
    }
}
button#delete-video {
    svg {
        font-size: 20px;
        margin-right: 4px;
    }
    div {
        font-size: 11px;
        padding: 0;
    }
}
.tags-chip {
    border: none;
    background-color: #E5EAEF;
    font-size: 11px;
    margin: 2px;
    padding: 7px 11px;
}
