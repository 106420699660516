.domain-card-players-table {
    .MuiTableHead-root {
        .MuiTableCell-root {
            text-transform: uppercase;
            font-weight: 600;
            font-size: 11px;
            border-bottom: 0;
            white-space: nowrap;
            padding-top: 20px;
        }
    }
    .MuiTableCell-root {
        font-size: 13px;
    }
    .MuiSvgIcon-root {
        color: black;
        &.MuiSvgIcon-colorDisabled { color: rgb(169,179,188); }
    }


    .MuiOutlinedInput-root {
        width: 118px;
    }
    .MuiSelect-select.MuiSelect-outlined {
        padding-left: 0;
        letter-spacing: 0.03em;
    }
    .MuiSelect-iconOutlined {
        font-size: 20px;
        right: 0;
    }
    .related-content-cell {
        .MuiFormControlLabel-root {
            display: flex;
            justify-content: space-between;
            width: 128px;
            .MuiFormControlLabel-label.MuiTypography-root {
                color: #3F4853;
                min-width: 0;
            }
        }
    }
    tbody tr {
        height: 50px;
    }

    tbody tr td {
        height: auto !important;
        padding-bottom: 0;
        padding-top: 0;
    }

    tbody tr td .MuiInputBase-formControl {
        margin-left: auto;
        margin-right: auto;
    }

    tbody tr td .MuiSwitch-track:before {
        left: 1.3em;
        top: 49%;
    }
    tbody tr td .MuiSwitch-track:after {
        right: 1.3em;
        top: 49%;
    }

}

.MuiMenu-root {
    margin-top: 0.8em;
}

.hint {
    font-style: italic;
    color: #91a0ad;
    font-size: 11px;
}

.div-with-hint {
    max-height: 25px;
}

.wrapper-modal-content {
    padding: 15px;
}

.urls-counter {
    width: 25px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 1px 8px;
    border-radius: 100px;
    background-color: rgba(0, 0, 0, 0.08);
}
.center-div {
    display: flex;
    justify-content: center;
    .MuiTextField-root {
        margin-right: 15px;
    }
    .MuiButton-root {
        margin-top: 1px;
    }
}

.left-div {
    display: flex;
    justify-content: flex-start;
    .MuiTextField-root {
        margin-left: 15px;
    }
    .MuiButton-root {
        margin-top: 1px;
    }
}

.outline-div {
    height: 3em;
    display: flex;
    align-items: center;
    flex: 1;
    font-size: 12px;
    border: 1px black solid;
    padding: 0 8px;
    margin: 26px 15px 0 0;
    max-width: 78%;
    overflow: hidden;
}

.text-overflow {
    width: 330px;
    padding: 2px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.button-close {
    outline: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
    margin: 26px 0 0 0;
}