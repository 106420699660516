.edit-playlist-modal-videos-collection {
  .amplify-flex.amplify-collection-items {
    gap: 34px 59px !important;
  }
}

.full-width-modal .MuiPaper-root {
  margin: auto 70px;
  max-width: none;
}

.MuiAccordion-root {
  margin-left: -8px !important;
}

.view-selector {
  display: flex;
  .MuiButtonBase-root > span {
    height: 20px;
  }
}