.confirm-modal.MuiModal-root {
  .MuiTypography-body1 {
    font-size: 11px;
  }
  .MuiDialogActions-root {
    margin-bottom: 0;
  }
  .MuiTypography-body1 {
    padding-right: 40px;
  }
  .MuiDialog-paper {
    height: 140px;
    padding-bottom: 10px;
  }
}

.confirm-modal,
.modal.MuiModal-root {
  .MuiDialog-paper {
    .MuiTypography-root.id {
      font-weight: 400;
      margin-top: 4px;
    }
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 23px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    .MuiDialogActions-root {
      padding-top: 10px;
      padding-right: 0;
      right: 10px;
      .MuiSvgIcon-root {
        font-size: 20px;
        color: #303237;
      }
      .MuiButtonBase-root {
        height: 31px;
        font-size: 11px;
        font-weight: 600;
      }
    }
    .MuiDialogContent-root {
      overflow-y: visible;
      margin-top: 0;
      margin-bottom: 0;
      .MuiTypography-root {
        font-weight: 500;
        line-height: normal;
      }
    }
  }
}

.create-property-modal.MuiModal-root {
  .MuiPaper-root {
    overflow-y: hidden;
  }
  .MuiDialogContent-root .MuiTypography-root,
  .MuiDialogContent-root .MuiFormControl-root {
    margin-bottom: 8px;
  }

  .MuiPaper-rounded {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 13px;
    padding-bottom: 23px;
    width: 560px;
    min-height: 160px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
}

.export-tag-modal.MuiModal-root {
  .MuiPaper-root {
    overflow-y: hidden;
  }
  .MuiDialogContent-root .MuiTypography-root,
  .MuiDialogContent-root .MuiFormControl-root {
    margin-bottom: 8px;
  }

  .MuiPaper-rounded {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 13px;
    padding-bottom: 23px;
    width: 560px;
    min-height: 160px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
}

.content-modal {
  .MuiPaper-root.MuiPaper-rounded .MuiDialogActions-root {
    top: 16px;
    right: 18px;
  }
  .MuiPaper-root.MuiPaper-rounded {
    padding-top: 20px;
  }
  .MuiDialogContent-root > .MuiDialogActions-root {
    margin-bottom: 0;
  }
}
.modal-edit-user {
  .MuiPaper-root.MuiPaper-rounded {
    width: 65%;
    max-width: 900px;
    height: 225px;
  }
  .MuiSelect-select {
    padding-left: 1px !important;
  }
}
.modal-publisher-list {
  .MuiPaper-root.MuiPaper-rounded {
    width: 560px;
    height: 660px;
    padding-left: 30px;
    padding-right: 30px;
    overflow: hidden;
  }
}
