.content-box {
  margin: 25px 0;
  #media-editor-form-container {
    .chip-container-tag {
      display: flex;
      flex-flow: row wrap;
      min-height: 7.25em;
      width: 435px;
      border: 1px solid #91A0AD;
      padding: 5px;
      & > div {
        margin-left: 5px;
        & > svg {
          font-size: 14px;
        }
      }
      span {
        padding: 7px 5px 7px 7px;
        font-size: 10px;
      }
      input[type="text"] {
        margin-left: 10px;
        padding: 9px 10px 5px 3px;
        font-size: 11px;
        height: 21px;
        border: none;
        &:focus {
          outline: none;
        }
      }
    }
  }
}