.MuiListItemButton-root.Mui-selected {
    &:hover {
        background-color: rgba(0, 0, 0, 0.04);
    }
    background-color: transparent;
}

.MuiListItemButton-root {
    .MuiSvgIcon-root {
        color: #C8D0D6;
    }

    .MuiSvgIcon-colorPrimary {
        color: #0033FF;
    }

    .MuiSvgIcon-fontSizeSmall {
        font-size: 16px;
    }

    .MuiTypography-root {
        font-size: 11px;
    }
}

.MuiTypography-root {
    font-family: 'Poppins', sans-serif;
}

.MuiCollapse-root .MuiCollapse-wrapperInner > div {
    padding-left: 7px;
}
